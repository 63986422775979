import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import translation from "./Translations.json";
import { Search } from "@mui/icons-material";
import "./SearchBar.style.scss";

const options = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
  keys: ["#text"],
};

export default function SearchBar() {
  const [db, setDb] = useState([]);
  const [search, setSearch] = useState("");
  const [fuse, setFuse] = useState(() => new Fuse(db, options));

  useEffect(() => {
    fetch("/static/searchDB.json")
      .then((r) => r.json())
      .then((data) => {
        fuse.setCollection(data);
        setDb(data);
      });
  }, []);

  function setRoute(url) {
    let route = url.split("/");
    let path = "";
    switch (route[2]) {
      case "guides":
        path = "Guides";
        break;
      case "advanced":
        path = "Fonctionnalités avancées";
        break;
    }

    let lang = route[1];
    let tr = translation[lang];
    let cat = route[2] === "advanced" ? "features" : route[2];

    let page = tr[cat].find((item) => item.link.includes(url));
    let pagename = page.name;

    let routeText = `${path} > ${pagename}`;
    return routeText;
  }

  let xResults;
  let isActive = "searchbar-block";
  if (search !== "") {
    isActive = "searchbar-block isActive";
    const filtered = fuse.search(search).slice(0, 5);
    if (filtered.length === 0) {
      xResults = (
        <p className="no-item-found">
          Aucun résultat ne correspond à la recherche
        </p>
      );
    } else {
      xResults = filtered.map((item) => (
        <a href={`${item.item["url"]}#${item.item["@_id"]}`}>
          <p>{item.item["#text"]}</p>
          <p>{setRoute(item.item["url"])}</p>
        </a>
      ));
    }
  }

  return (
    <div className="searchbar-root">
      <div className={isActive}>
        <Search />
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Rechercher dans la documentation"
        />
        <div className="searchbar-results">{xResults}</div>
      </div>
    </div>
  );
}
