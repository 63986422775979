import React, { useState, useEffect } from "react";
import clx from "clsx";

import { showable } from "../helpers/showable";

import "./Scroll.style.scss";
import img from "../images/scrollbutton.png";

interface IScrollProps {
  showBelow: number;
}

function Scroll({ showBelow }: IScrollProps) {
  const [show, setShow] = useState(showable(showBelow));

  const handleScroll = () => {
    setShow(showable(showBelow));
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll);
    return () => window.removeEventListener(`scroll`, handleScroll);
  }, []);

  return (
    <img
      src={img}
      className={clx("scrolltop-btn", {
        hide: show === false,
      })}
      onClick={handleClick}
    />
  );
}

export default Scroll;
