import React, { useState } from "react";
import SearchBar from "./SearchBar";
import setIcon from "./FeatureIcon";
import tr from "./Translations.json";
import {
  Home,
  Construction,
  School,
  PlayCircleOutline,
  EmojiObjects,
} from "@mui/icons-material";
import "./NavBar.style.scss";
import Logo from "../images/logo-dotter-documentation-white.svg";

export default function NavBar() {
  let xtutolist = tr.fr.tutorials.map((item) => (
    <a href={item.link} className="navlink-item">
      <li>
        <PlayCircleOutline />
        <span>{item.name}</span>
      </li>
    </a>
  ));

  let xguideslist = tr.fr.guides.map((item) => (
    <a href={item.link} className="navlink-item">
      <li>
        <span>{setIcon(item.icon)}</span>
        <span>{item.name}</span>
      </li>
    </a>
  ));

  let xfeatureslist = tr.fr.features.map((item) => (
    <a href={item.link} className="navlink-item">
      <li>
        <span>{setIcon(item.icon)}</span>
        <span>{item.name}</span>
      </li>
    </a>
  ));

  const [menuStatus, setMenuStatus] = useState(false);
  const [menuClass, setMenuClass] = useState("menu-content close");

  function handleMenu() {
    if (menuStatus === false) {
      setMenuClass("menu-content open");
      setMenuStatus(true);
    } else {
      setMenuClass("menu-content close");
      setMenuStatus(false);
    }
  }
  console.log(menuStatus, menuClass);
  return (
    <div className="navbar-root">
      <a className="navbar-left" href="/">
        <img src={Logo} />
        <span className="navbar-title">
          <h1>Dotter.science</h1>
          <h3>documentation</h3>
        </span>
      </a>
      <SearchBar />
      <div className="navbar-right">
        <div className="navdrawer">
          <a href="/fr/tutorial" className="navlink">
            Tutoriels <School />
          </a>
          <div className="navdrawer-content">{xtutolist}</div>
        </div>
        <div className="navdrawer">
          <a href="/fr/guides" className="navlink">
            Guides <EmojiObjects />
          </a>
          <div className="navdrawer-content">{xguideslist}</div>
        </div>
        <div className="navdrawer">
          <a href="/fr/advanced" className="navlink">
            Fonctionnalités avancées <Construction />
          </a>
          <div className="navdrawer-content">{xfeatureslist}</div>
        </div>
        <a href="/" className="homebutton">
          <Home />
        </a>
      </div>
      <div className="navbar-mobile-menu">
        <div className="menu-button" onClick={(e) => handleMenu()}>
          <b>☰ Menu</b>
        </div>
        <div className={menuClass}>
          <a href="/fr/tutorial" className="navlink">
            Tutoriels <School />
          </a>
          <a href="/fr/guides" className="navlink">
            Guides <EmojiObjects />
          </a>
          <a href="/fr/advanced" className="navlink">
            Fonctionnalités avancées <Construction />
          </a>
        </div>
      </div>
    </div>
  );
}
