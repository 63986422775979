import React from "react";
import { Helmet } from "react-helmet";
import clx from "clsx";
import NavBar from "./NavBar";
import Scroll from "./Scroll";
import RouteDisplay from "./RouteDisplay";
import favicon from "../images/logo-dotter-documentation-white.svg";

interface ILayoutProps {
  title: string;
  children: any;
  scrollable?: boolean;
  noscroll?: boolean;
}

function Layout({ title, children, scrollable, noscroll }: ILayoutProps) {
  let url = "";
  let route = [];
  if (typeof window !== "undefined") {
    url = window.location.pathname;
    route = url.split("/").filter((item) => item !== "");
  }

  let xScroll;
  if (noscroll) {
    xScroll = null;
  } else {
    xScroll = <Scroll showBelow={250} />;
  }

  return (
    <div
      className={clx("root", {
        scrollable: scrollable === true,
      })}
    >
      <Helmet>
        <title>{title} | Documentation</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400&family=Poppins:wght@500&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Crete+Round&display=swap"
          rel="stylesheet"
        ></link>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <NavBar />
      {xScroll}
      <RouteDisplay route={route} />
      <div className="root">{children}</div>
    </div>
  );
}
export default Layout;
