import React, { useState, useEffect } from "react";
import translation from "./Translations.json";
import { Article } from "@mui/icons-material";
import "./RouteDisplay.style.scss";

interface IRouteDisplayProps {
  route: Array<string>;
}

export default function RouteDisplay({ route }: IRouteDisplayProps) {
  const [routeDisplayRoot, setRouteDisplayRoot] = useState("");
  let path;

  if (route.length >= 3 && route[1] !== "tutorial") {
    useEffect(() => {
      setRouteDisplayRoot("route-display-root");
    }, []);

    switch (route[1]) {
      case "guides":
        path = "Guides";
        break;
      case "advanced":
        path = "Fonctionnalités avancées";
        break;
    }

    let url = `${route[0]}/${route[1]}/${route[2]}`;
    let lang = route[0];
    let tr = translation[lang];
    let cat = route[1] === "advanced" ? "features" : route[1];

    let currentpage = tr[cat].find((item) => item.link.includes(url));
    let pagename = currentpage.name;

    return (
      <div className="test">
        <div className={routeDisplayRoot}>
          <span>
            <Article />
          </span>
          <span>
            <a href={`/fr/${route[1]}/`}>{path}</a>{" "}
          </span>
          <span> &gt; </span>
          {pagename}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
