import React from "react";

import {
  Settings,
  FormatListBulleted,
  GridView,
  AssessmentOutlined,
  CalculateOutlined,
  FactCheckOutlined,
  CasinoOutlined,
  FileDownloadOutlined,
  RuleOutlined,
  ChatOutlined,
  FlagOutlined,
  HistoryOutlined,
  MonitorOutlined,
  AttachFileOutlined,
  AssignmentLateOutlined,
  ScreenSearchDesktop,
  LockOpen,
  ManageAccounts,
  AdminPanelSettings,
  LockOutlined,
} from "@mui/icons-material";

export default function setIcon(icon: string) {
  switch (icon) {
    case "FormatListBulleted":
      return <FormatListBulleted />;
    case "GridView":
      return <GridView />;
    case "AssessmentOutlined":
      return <AssessmentOutlined />;
    case "CalculateOutlined":
      return <CalculateOutlined />;
    case "FactCheckOutlined":
      return <FactCheckOutlined />;
    case "Shuffle":
      return <CasinoOutlined />;
    case "FileDownloadOutlined":
      return <FileDownloadOutlined />;
    case "RuleOutlined":
      return <RuleOutlined />;
    case "ChatOutlined":
      return <ChatOutlined />;
    case "FlagOutlined":
      return <FlagOutlined />;
    case "HistoryOutlined":
      return <HistoryOutlined />;
    case "MonitorOutlined":
      return <MonitorOutlined />;
    case "AttachFileOutlined":
      return <AttachFileOutlined />;
    case "AssignmentLateOutlined":
      return <AssignmentLateOutlined />;
    case "ScreenSearchDesktop":
      return <ScreenSearchDesktop />;
    case "LockOpen":
      return <LockOpen />;
    case "Lock":
      return <LockOutlined />;
    case "ManageAccounts":
      return <ManageAccounts />;
    case "AdminPanelSettings":
      return <AdminPanelSettings />;
    default:
      return <Settings />;
  }
}
